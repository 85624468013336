import { navigate } from "gatsby";
import React, { useState } from "react";
import { useRealmApp } from "./realm_app";
import PrevNextButtons from "./prev_next";

import "react-phone-input-2/lib/style.css";

export const MentorshipDescrInput = ({
  prompt_text,
  example_text,
  descr_text_attr,
  has_prev_button = false,
  prev_button_text = null,
  onClickPrev = () => {},
  next_button_text = "next",
  postSaveFunc = () => {},
}) => {
  const realmApp = useRealmApp();
  const defaultText = example_text;
  const existingText = realmApp.app.currentUser?.customData[descr_text_attr];

  const [loading, setLoading] = useState("");
  const [text, setText] = useState(existingText ? existingText : defaultText);
  const [textAreaStyle, setTextAreaStyle] = useState({
    width: "400px",
    height: "200px",
    color: existingText ? "#000000" : "#808080",
    border: "none",
    outline: "none",
    background: "#f0f3f7",
  });

  function onFocusTextArea(focus) {
    const newTextAreaStyle = { ...textAreaStyle };
    if (focus) {
      newTextAreaStyle["color"] = "#000000";
      setTextAreaStyle({ ...newTextAreaStyle });
    }
    if (focus && text == defaultText) {
      setText("");
    }
    if (!focus && text == "") {
      setText(defaultText);
      newTextAreaStyle["color"] = "#808080";
      setTextAreaStyle({ ...newTextAreaStyle });
    }
  }

  async function saveMentorshipDescr() {
    setLoading("Saving preference...");
    try {
      if (text == defaultText || text == "") {
        alert(
          "Please fill in the prompt. Anything helps give you the best match!"
        );
      } else {
        var to_set = {};
        to_set[descr_text_attr] = text;
        await realmApp.setMongoUserAttribute(to_set);
        postSaveFunc();
      }
    } finally {
      setLoading("");
    }
  }

  return (
    <>
      <div className="page-content-center">
        <h3>Matching Preferences</h3>
      </div>
      <div className="page-content-center">{prompt_text}</div>
      <div className="page-content-center">
        <div className="page-content page-content-med">
          <br />
          <textarea
            style={textAreaStyle}
            value={text}
            onChange={(e) => setText(e.target.value)}
            onFocus={() => {
              onFocusTextArea(true);
            }}
            onBlur={() => {
              onFocusTextArea(false);
            }}
          />
        </div>
      </div>
      <br />
      <div className="page-content-center">
        <PrevNextButtons
          hasPrev={has_prev_button}
          prevText={prev_button_text}
          onClickPrev={onClickPrev}
          hasNext={true}
          nextText={next_button_text}
          onClickNext={saveMentorshipDescr}
        />
      </div>
      <div className="page-content-center">
        <br />
        {loading}
      </div>
    </>
  );
};
