import { navigate } from "gatsby";
import React, { useState } from "react";
import {
  RealmAppProvider,
  OnboardingRedirector,
  APP_ID,
} from "../../../components/realm_app";
import OnboardingNavbar from "../../../components/onboarding_navbar";
import { Footer } from "../../../components/footer";

import "react-phone-input-2/lib/style.css";

import WelcomeHeader from "../../../components/welcome_header";
import { MentorshipDescrInput } from "../../../components/mentorship_descr_input";

const SelfDescrPage = ({ location }) => {
  const onboarding =
    new URL(location.href).searchParams.get("state") == "onboarding";

  const example_text = [
    "I’m a softy and mostly like to listen...",
    "Listening is boring, I like to lead the conversation...",
  ].join("\n");
  return (
    <>
      <div className="page">
        <OnboardingNavbar />
        <MentorshipDescrInput
          prompt_text="What's your communication style?"
          example_text={example_text}
          descr_text_attr="mentorship_self_descr"
          postSaveFunc={() => {
            navigate("/member/dashboard");
          }}
          has_prev_button={true}
          next_button_text={onboarding ? "next" : "save"}
          prev_button_text={onboarding ? "back" : "cancel"}
          onClickPrev={
            onboarding
              ? () => {
                  navigate("/member/signup/other_descr?state=onboarding");
                }
              : () => {
                  navigate("/member/dashboard");
                }
          }
        />
      </div>
      <Footer />
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      <OnboardingRedirector location={location}>
        <SelfDescrPage location={location} />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
