import React from "react";
import { Button } from "react-bootstrap";

export default ({
  hasPrev = false,
  hasNext = false,
  prevText = "Previous",
  nextText = "Next",
  onClickPrev = () => {},
  onClickNext = () => {},
}) => {
  var hasBoth = hasPrev && hasNext;
  var maxWidthPx = hasBoth ? "200px" : "67px";

  var prevButton = (
    <Button className="light-gray-button" onClick={onClickPrev}>
      {prevText}
    </Button>
  );
  var nextButton = (
    <Button className="light-green-button" onClick={onClickNext}>
      {nextText}
    </Button>
  );

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {hasPrev ? prevButton : null}
      {hasBoth ? <div style={{ width: "100%" }} /> : null}
      {hasNext ? nextButton : null}
    </div>
  );
};
